<template>
  <v-layout :fill-height="true" class="Login">
    <v-row align="center" justify="center">
      <v-col lg="3" md="5" sm="7">
        <v-card outlined>
          <v-card-title class="Login__logo-container pt-8">
            <img
              class="Login__logo-image"
              src="@/assets/images/shifthealth_login_logo.png"
              alt="Shifthealth.io"
            />
          </v-card-title>
          <v-card-text class="py-10">
            <validation-observer v-slot="{ handleSubmit }">
              <form @submit.prevent="handleSubmit(onSubmit)">
                <v-row>
                  <v-col cols="11" class="mx-auto pb-0">
                    <validation-provider
                      v-slot="{ errors }"
                      rules="required|email"
                      name="email"
                    >
                      <v-text-field
                        label="E-mail"
                        name="email"
                        flat
                        outlined
                        type="email"
                        :hide-details="false"
                        :error="errors.length > 0"
                        :error-count="errors.length"
                        :error-messages="errors"
                        v-model="email"
                      />
                    </validation-provider>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col cols="11" class="mx-auto pb-0">
                    <validation-provider
                      v-slot="{ errors }"
                      rules="required"
                      name="password"
                    >
                      <v-text-field
                        label="Password"
                        flat
                        outlined
                        type="password"
                        :hide-details="false"
                        :error="errors.length > 0"
                        :error-count="errors.length"
                        :error-messages="errors"
                        v-model="password"
                      />
                    </validation-provider>
                  </v-col>
                </v-row>
                <v-row v-if="hasError">
                  <v-col cols="11" class="mx-auto py-0">
                    <p class="error--text mb-0">
                      {{ errorMessage }}
                    </p>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col cols="11" class="mx-auto">
                    <v-btn
                      type="submit"
                      color="primary"
                      block
                      large
                      :loading="isAuthenticating"
                      :disabled="isAuthenticating"
                    >
                      Login
                    </v-btn>
                  </v-col>
                </v-row>

                <v-row justify="center">
                  <v-col cols="auto">
                    <a
                      class="text-primary text-center mx-auto"
                      @click="resetPassword()"
                    >
                      Forgot password?
                    </a>
                  </v-col>
                </v-row>
              </form>
              <v-dialog v-model="showResetPwdDialog" width="600">
                <v-card>
                  <v-card-title primary-title>Success</v-card-title>
                  <v-card-text>
                    A link with a temporary password has been sent to your
                    email. Use it to log in and change it in the settings
                    afterwards.
                  </v-card-text>
                  <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn
                      color="primary"
                      text
                      @click="showResetPwdDialog = false"
                    >
                      Got it
                    </v-btn>
                  </v-card-actions>
                </v-card>
              </v-dialog>
            </validation-observer>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </v-layout>
</template>

<script>
import { createNamespacedHelpers } from 'vuex';

const {
  mapActions: AuthActions,
  mapGetters: AuthGetters,
  mapMutations: AuthMutation,
} = createNamespacedHelpers('auth');

export default {
  name: 'Login',
  components: {},
  data() {
    return {
      email: '',
      password: '',
    };
  },
  computed: {
    ...AuthGetters({
      isAuthenticating: 'IS_AUTHENTICATING',
      hasError: 'AUTHENTICATION_ERROR',
      errorMessage: 'AUTHENTICATION_MESSAGE',
    }),
    showResetPwdDialog: {
      get() {
        return this.$store.getters['auth/SHOW_PASSWORD_RESET_DIALOG'];
      },
      set(value) {
        this.setShowPasswordResetDialog(value);
      },
    },
  },
  methods: {
    ...AuthActions(['signIn', 'passwordReset']),
    ...AuthMutation({
      setShowPasswordResetDialog: "SET_SHOW_PASSWORD_RESET_DIALOG",
    }),
    onSubmit() {
      this.signIn({ email: this.email, password: this.password });
    },
    async resetPassword() {
      await this.passwordReset({ email: this.email });
    },
  },
};
</script>

<style lang="scss" scoped>
.Login {
  &__logo-container {
    display: flex;
    justify-content: center;
  }

  &__logo-image {
    width: 300px;
  }
}
</style>
